<template>
  <Transition name="fade">
    <header v-show="!hidden" class="o-header" :class="{ 'hidden': hidden }">
      <div class="o-header__logo">
        <img
          class="o-header__logo"
          alt="Логотип VK Fest"
          src="../../../assets/images/logo.svg"
          @click="[
            $router.push('/'),
            commonStore.isOpenMenu = false
          ]"
        >
      </div>
      <div class="o-header__menu">
        <button
          class="o-header__hamburger"
          type="button"
          aria-label="Открыть меню"
          @click="toggleMenu()"
        >
          <div
            class="o-header__hamburger-inner"
            :class="{ 'is-open': isMenuOpen }"
          />
        </button>
      </div>
    </header>
  </Transition>

  <div
    class="o-header__dropdown-menu"
    :class="{ 'is-open': isMenuOpen }"
  >
    <div
      class="o-header__dropdown-menu-overlay"
      @click="toggleMenu()"
    />

    <div class="o-header__dropdown-menu-content" @click="toggleMenu()">
      <img src="/assets/animations/images/pattern.svg" alt="animate" class="o-header__dropdown-menu-content__animation top">

      <div class="o-header__dropdown-menu-content__nav-wrapper">
        <nav>
          <template v-for="(city, key) in commonStore.cities" :key="key">
            <AButton
              v-if="!city.soon"
              :size-lg="{ size: 32, line: 48 }"
              :size-sm="{ size: 22, line: 32 }"
              link
            >
              <NuxtLink :to="`/city/${key}`">
                {{ city.name }}
              </NuxtLink>
            </AButton>
          </template>
          <AButton
            :size-lg="{ size: 32, line: 48 }"
            :size-sm="{ size: 22, line: 32 }"
            link
          >
            <NuxtLink
              :href="SETTINGS.communityLink"
              target="_blank"
            >
              Сообщество<br>VKFEST 2025
            </NuxtLink>
            <NuxtIcon name="arrow" filled class="arrow-icon" />
          </AButton>
          <div class="download_app">
            <AButton
              :size-lg="{ size: 32, line: 48 }"
              :size-sm="{ size: 22, line: 32 }"
              link
            >
              <NuxtLink
                to="/apps"
                @click="sendAnalytics('open_apps')"
              >
                Загрузить<br>приложение
              </NuxtLink>
            </AButton>
            <img src="../../../assets/icons/app.svg" alt="App logo">
          </div>
          <AButton
            :size-lg="{ size: 32, line: 48 }"
            :size-sm="{ size: 22, line: 32 }"
            link
          >
            <NuxtLink to="/contact-us">
              Стать участником
            </NuxtLink>
          </AButton>
        </nav>
      </div>
      <img src="/assets/animations/images/4.png" alt="animate" class="o-header__dropdown-menu-content__animation bottom">
    </div>
  </div>
</template>

<script setup lang="ts">
import AButton from '~/components/atoms/button/a-button.vue';
import useCommonStore from '~/store/common';
import { SETTINGS } from '~/constants';
import AAnimation from '~/components/atoms/animation/a-animation.vue';
import MReq from '~/components/molecules/animation/m-animation.vue';
import { sendAnalytics } from '~/utils/useAnalytics';

interface Props {
  hidden?: boolean,
}

withDefaults(defineProps<Props>(), {
  hidden: false,
});

const commonStore = useCommonStore();

const isMenuOpen = computed(() => commonStore.isOpenMenu);

const toggleMenu = () => {
  commonStore.toggleMenu();
};

const closeMenuEsc = () => {
  commonStore.isOpenMenu = false;
};

const handleKeydown = (e: KeyboardEvent): void => {
  if (e.code === 'Escape') closeMenuEsc();
};

watch(() => isMenuOpen.value, (value) => {
  if (value) {
    document.body.style.overflow = 'hidden';
  }
  else {
    document.body.style.overflow = 'unset';
  }
});

onMounted(() => {
  document.addEventListener('keydown', handleKeydown);
});
</script>

<style scoped src="./style.css" />
