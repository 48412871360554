<template>
  <div class="default-layout">
    <MHeader :hidden="showHeader" />

    <slot />
  </div>
</template>
<script setup lang="ts">
import MHeader from '~/components/organisms/header/o-header.vue';

const device = useDevice();

const isMobile = computed(() => device.isMobile.value);
const showHeader = ref(false);

const handleScroll = () => {
  showHeader.value = window.scrollY > 200;
};

onMounted(() => {
  if (isMobile.value) {
    window.addEventListener('scroll', handleScroll);
  }
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<style lang="postcss">
.hidden {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transform: translateY(-100%);
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.4s ease, transform 0.4s ease;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.fade-enter-to, .fade-leave-from {
  opacity: 1;
}
</style>
